import styled from "styled-components";

import { ReactComponent as Arrow } from "../../assets/imgs/icons/arrow.svg";
import Image from "../../assets/imgs/gallery/kids.jpg";
import Wall from "../../assets/imgs/wall.png";

const Step2 = () => {
  return (
    <Style>
      <div className="container">
        <div data-aos="fade">
          <h3>
            <span>Kids Cut</span>
          </h3>
          <p>
          Nothing beats affordable mens haircuts, unless it’s affordable kids haircut. For kids at the age of 3+. Keep your kids fresh whether its the first day of school or first day of summer.
          </p>
          <header>
            <h1>02</h1>
            <Arrow />
          </header>
        </div>
        <div>
          <img src={Image} alt="" data-aos="fade" />
        </div>
      </div>
    </Style>
  );
};
export default Step2;

const Style = styled.div`
  background: #0f1011;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;

  img {
    box-shadow: 48px 56px 72px rgba(58, 58, 58, 0.24);
    border-radius: 24px;
    width: 600px;
    display: inline-block;
    object-fit: cover;
    height: 608px;
  }
  .container {
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      :first-child {
        margin-right: 24px;
        @media (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 70px;
    .container {
      flex-direction: column;
    }
    header {
      justify-content: center;
    }
    img {
      width: 312px;
      height: 322px;
    }
  }
  b,
  h3 {
    color: #ff961b;
    position: relative;
    z-index: 1;
  }
  h3 {
    display: inline-block;
    span {
      position: relative;
      z-index: 1;
    }
    ::before {
      content: url(${Wall});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 768px) {
        transform: translate(-50%, -50%) scale(0.7);
      }
    }
  }
  p {
    margin-top: 16px;
    max-width: 461px;
    position: relative;
    z-index: 1;
  }
  header {
    display: flex;
    align-items: center;
    h1 {
      color: #d82525;
      font-size: 176px;
      @media (max-width: 768px) {
        font-size: 120px;
      }
    }
    svg {
      margin-left: 15px;
      transform: rotate(180deg);
    }
  }
`;
