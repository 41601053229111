import styled from "styled-components";

import { ReactComponent as Arrow } from "../../assets/imgs/icons/arrowH.svg";

const Step4 = ({ ...props }) => {
  return (
    <Style {...props}>
      <header data-aos="fade">
        <Arrow />
        <h1>04</h1>
      </header>
      <h3 data-aos="fade">Eyebrow Waxing</h3>
      <p data-aos="fade">
        Match one of our new haircut designs with a clean, sharp look crafted by
        careful eyebrow waxing and nose hair removal. We offer an array of
        barber services at <b>Kreative Kuts</b> in Merrillville, IN designed to
        help you look and feel confident. If you are looking for affordable
        grooming services close to home, then come and visit our friendly
        barbers today.
      </p>
    </Style>
  );
};
export default Step4;

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  header {
    color: #ff961b;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 176px;
      @media (max-width: 768px) {
        font-size: 120px;
      }
    }
    svg {
      path {
        fill: #ff961b;
      }
    }
  }
  h3 {
    color: #ff961b;
  }
  p {
    text-align: center;
    max-width: 380px;
    margin-top: 16px;
    b {
      color: #ff961b;
    }
  }
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 80px;
  }
`;
