import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Logo } from "../assets/imgs/logo/logo.svg";
import { ReactComponent as CloseIcon } from "../assets/imgs/icons/close.svg";
import { ReactComponent as Menu } from "../assets/imgs/icons/menu.svg";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  return (
    <Style className={scroll ? "scroll" : ""}>
      <div className="container">
        <Brand className="brand" />
        <Nav className={menu ? "show" : ""}>
          <CloseIcon className="close" onClick={() => setMenu(false)} />
          <Brand />
          <div className="closeSidebar" onClick={() => setMenu(false)}></div>
          <Links>
            <a href="#home" onClick={() => setMenu(false)}>
              Home
            </a>
            <a href="#services" onClick={() => setMenu(false)}>
              Services
            </a>
            <a href="#reviews" onClick={() => setMenu(false)}>
              Reviews
            </a>
            <a href="#gallery" onClick={() => setMenu(false)}>
              Gallery
            </a>
            <a href="#contactus" onClick={() => setMenu(false)}>
              Contact Us
            </a>
          </Links>
        </Nav>
        <Menu className="menu" onClick={() => setMenu(true)} />
      </div>
    </Style>
  );
};

export default Navbar;

const Style = styled.nav`
  z-index: 10;
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  width: 100%;
  transition: top 200ms, background-color 200ms;
  padding-top: 15px;
  padding-bottom: 15px;

  .menu {
    cursor: pointer;
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .brand {
    position: relative;
    top: 1.5px;
    a {
      display: inline-block;
    }
  }
  // on Scrole
  &.scroll {
    top: 0;
    background-color: #0f1011;
    transition: top 200ms, background-color 200ms;
    .brand {
      svg {
        height: 40px;
        width: auto;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Links = styled.div`
  a {
    white-space: nowrap;
    font-size: 24px;
    font-family: "Outfit Regular";
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-inline: clamp(10px, 1vw, 20px);
    :hover {
      color: #ff961b;
      /* font-family: "Outfit Bold"; */
    }
  }

  @media (max-width: 992px) {
    font-size: 22px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;

    a:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  .close {
    box-sizing: content-box;
    margin: 15px;
    margin-left: auto;
    padding: 10px;
    cursor: pointer;
    display: none;
  }
  ul {
    padding-left: 0;
  }
  .brand {
    display: none;
    margin-bottom: 45px;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: -200%;
    bottom: 0;
    /* background-color: #040918; */
    background-color: #0f1011;
    width: min(350px, 80vw);
    flex-direction: column;
    justify-content: flex-start;
    transition: left 300ms;
    .close,
    .brand {
      display: block;
    }
    &.show {
      left: 0;
      transition: left 300ms;

      .closeSidebar {
        left: 0;
      }
    }
    .closeSidebar {
      background: rgba(6, 10, 21, 0.73);
      cursor: pointer;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
    }
  }
`;

export const Brand = () => (
  <div className="brand">
    <Link to="/">
      <Logo />
    </Link>
  </div>
);
