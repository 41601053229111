import React from "react";

import Hero from "../layout/Hero";
import Navbar from "../layout/Navbar";
import OurServices from "../sections/OurServices";
import OurGallery from "../sections/OurGallery";
import OurReviews from "../sections/OurReviews";
import Footer from "../layout/Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <OurServices />
      <OurReviews />
      <OurGallery />
      <Footer />
    </>
  );
};

export default Home;
