import React from "react";
import styled from "styled-components";

import Carousel from "../components/Carousel";

const OurReviews = () => {
  return (
    <Style id="reviews">
      <div className="container">
        <h2 data-aos="fade">
          <span>Our</span> Reviews
        </h2>
        <Carousel />
      </div>
    </Style>
  );
};

export default OurReviews;

const Style = styled.section`
  padding-top: 70px;
  padding-bottom: 80px;
  overflow: hidden;
  background-color: ${(props) => props.theme.bg};
  h2 {
    margin-bottom: 32px;
    text-align: center;
    color: #d82525;
    span {
      color: ${(props) => props.theme.text};
    }
  }
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;
