import React from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "../assets/imgs/icons/arrow.svg";

const CarouselArrows = () => {
  return (
    <Arrows className="arrows">
      <button className="left">
        <Arrow />
      </button>
      <button className="right">
        <Arrow />
      </button>
    </Arrows>
  );
};

export default CarouselArrows;

const Arrows = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border: 1px solid;
    border-color: ${(props) => (props.theme.dark ? "#FF961B" : "#D82525")};
    background: none;
    padding: 17px 21px;
    border-radius: 32px;
    max-width: auto;
    svg {
      margin: 0 auto;
      height: auto;
      width: 38px;
      path {
        fill: ${(props) => (props.theme.dark ? "#FF961B" : "#D82525")};
      }
    }
    :hover {
      background-color: #d82525;
      svg {
        path {
          fill: ${(props) => (props.theme.dark ? "#FF961B" : "#ffffff")};
          transition: fill 0.2s ease-in-out;
        }
      }
    }
    &:first-child {
      margin-right: 24px;
      svg {
        transform: rotate(-180deg);
      }
    }
  }
`;
