import styled from "styled-components";

import { ReactComponent as Arrow } from "../../assets/imgs/icons/arrow.svg";
import Image from "../../assets/imgs/gallery/9.jpg";
import Wall from "../../assets/imgs/wall.png";

const Step3 = ({ className }) => {
  return (
    <Style className={"container " + className}>
      <div>
        <img src={Image} alt="" data-aos="fade" />
        <div data-aos="fade">
          <h3>
            <span>Beard Trim</span>
          </h3>
          <p>
          Whether you need a beard trim or want a complete hot towel shave, you’ll find Kreative Kuts is the place to be. Our cozy, friendly atmosphere matches our barbershop services. Kreative Kuts in Merrillville, IN is the perfect place to relax while you leave the beard trimming to the professionals. We’ll make sure you look your absolute best!
          </p>
        </div>
        <header data-aos="fade">
          <Arrow />
          <h1>03</h1>
        </header>
      </div>
    </Style>
  );
};
export default Step3;

const Style = styled.div`
  color: #fff;
  > div {
    display: flex;
    flex-direction: column;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  h3 {
    color: #ff961b;
    position: relative;
    span {
      position: relative;
      z-index: 1;
    }
    ::before {
      content: url(${Wall});
      position: absolute;
      top: 0;
      left: 0;
      @media (max-width: 768px) {
        transform: scale(0.7);
      }
    }
  }
  p {
    position: relative;
    z-index: 1;
    margin-top: 15px;
    b {
      color: #ff961b;
    }
  }
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 176px;
      margin-left: 15px;
      @media (max-width: 768px) {
        font-size: 120px;
      }
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
  img {
    width: 460px;
    height: 473px;
    box-shadow: 48px 56px 72px rgba(58, 58, 58, 0.24);
    border-radius: 24px;
    display: inline-block;
    object-fit: cover;
    margin: 0 auto 50px;

    @media (max-width: 768px) {
      width: 264px;
      height: 271px;
      margin-bottom: 32px;
    }
  }
`;
