const Cards = [
  {
    paragraph:
      "Very professional and was not disappointed with my boys haircuts. I will bring my kids here again, no doubt about it.",
  },
  {
    paragraph:
      "If Juanita can make my hair look good, she can make everyone look good!!!",
  },
  {
    paragraph:
      "It is true of walk-ins are welcome I walked in and they were ready to seat me and I have a bald head",
  },
  {
    paragraph:
      "Very professional and was not disappointed with my boys haircuts. I will bring my kids here again, no doubt about it.",
  },
  {
    paragraph:
      "Very professional and was not disappointed with my boys haircuts. I will bring my kids here again, no doubt about it.",
  },
  {
    paragraph:
      "Very professional and was not disappointed with my boys haircuts. I will bring my kids here again, no doubt about it.",
  },
  {
    paragraph:
      "Very professional and was not disappointed with my boys haircuts. I will bring my kids here again, no doubt about it.",
  },
];

export default Cards;
