import styled from "styled-components";

import { ReactComponent as Arrow } from "../../assets/imgs/icons/arrow.svg";
import Image from "../../assets/imgs/gallery/mens.jpg";

const Step1 = () => {
  return (
    <Style dfsdf="dfdf">
      <div className="container">
        <div>
          <img src={Image} alt="" data-aos="fade" />
        </div>
        <div data-aos="fade">
          <header>
            <Arrow />
            <h1>01</h1>
          </header>
          <h3>Mens Cut</h3>
          <p>
            At <b>Kreative Kuts</b> in Merrillville, IN, you can get a mens
            haircut at a low cost! Nothing beats affordable kids haircuts,
            unless it’s affordable kids’ haircuts with incredible style! From
            razor line ups to stylish fades, everyone will leave our barbershop
            looking their best. Our men’s haircuts are affordable too, and if
            you need a mustache trim to go with it, then you’re in luck!
          </p>
        </div>
      </div>
    </Style>
  );
};
export default Step1;

const Style = styled.div`
  padding-bottom: 100px;
  img {
    display: inline-block;
    border-radius: 24px;
    box-shadow: 48px 56px 72px rgba(58, 58, 58, 0.24);
    width: 600;
    height: 535px;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 313px;
      height: 345px;
    }
  }
  .container {
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      :first-child {
        margin-right: 24px;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      > * {
        :first-child {
          margin-right: 0;
          margin-bottom: 32px;
        }
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    h1 {
      color: #d82525;
      font-size: 176px;
      @media (max-width: 768px) {
        font-size: 120px;
      }
    }
    svg {
      margin-right: 15px;
    }
  }
  h3 {
    margin-bottom: 16px;
    color: ${(props) => props.theme.text};
  }
  p {
    color: ${(props) => props.theme.text};
    max-width: 380px;
  }
  b {
    color: #d82525;
  }
`;
