import React from "react";
import styled from "styled-components";

import herobg from "../assets/imgs/hero.jpg";

import { ReactComponent as Telegram } from "../assets/imgs/icons/telegram.svg";
import { ReactComponent as Arrow } from "../assets/imgs/icons/smallArrow.svg";

const Hero = () => {
  return (
    <Style id="home">
      <div className="container">
        <h4 data-aos="fade-down">WELCOME TO</h4>
        <h1 data-aos="fade-down">KREATIVE KUTS</h1>
        <div data-aos="fade">
          <a href="https://kreativekuts5.booksy.com">
          <button className="btn">
           
          
            <span>Book Now</span>
            <Telegram />
          </button>
          </a>
          <a href="#services" className="goDown">
            <Arrow />
          </a>
        </div>
      </div>
    </Style>
  );
};

export default Hero;

const Style = styled.section`


  background: url(${herobg}) no-repeat center;
  background-size: cover;
  background-position:center;
  display: flex;
  max-height: 900px;
  min-height: 80vh;
  @media (min-width: 768px) {
    min-height: 900px;
  }
  @media (max-width: 768px) {
    height: 90vh;
  }
  .container {
    margin-bottom: 70px;
    margin-top: auto;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .btn {
        margin-bottom: 32px;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 50px;
    }
  }
  h4 {
    text-align: center;
    color: #ff961b;
    margin-bottom: 24px;
  }
  h1 {
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
    letter-spacing: 0.32em;
  }
  .goDown {
    border: 1px solid #ffffffaf;
    padding: 19px;
    border-radius: 32px;
    display: inline-block;
    svg {
      animation: bounce 2s infinite;
    }
    :hover {
      background: #ff961b;
      border-color: #ff961b;
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-12px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;

const Button = styled.a``;
