import React from "react";
import styled from "styled-components";

import { ReactComponent as FiveStars } from "../assets/imgs/icons/fivestars.svg";

const Card = ({ paragraph }) => {
  return (
    <CardStyle className="card" data-aos="fade-left">
      <Stars>
        <FiveStars />
      </Stars>
      <Paragraph>
        <p>{paragraph}</p>
      </Paragraph>
    </CardStyle>
  );
};

export default Card;

const CardStyle = styled.div`
  background: ${(props) => (props.theme.dark ? "#D82525" : "#ffffff")};
  box-shadow: 16px 16px 72px rgba(58, 58, 58, 0.16);
  border-radius: 16px;
  width: 100%;
  height: 200px;
`;

const Paragraph = styled.div`
  padding: 32px 24px;
  p {
    color: ${(props) => props.theme.text};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // number of lines to show
    -webkit-box-orient: vertical;
    line-height: 1.2;
  }
  @media (max-width: 768px) {
    padding: 24px 16px;
  }
`;

const Stars = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.theme.dark ? "#D82525" : "#0f1011")};
  padding: 24px 0;
  border-radius: 16px 16px 0px 0px;
`;
