import React from "react";
import styled from "styled-components";

import img1 from "../assets/imgs/gallery/1.jpg";
import img2 from "../assets/imgs/gallery/2.jpg";
import img3 from "../assets/imgs/gallery/3.jpg";
import img4 from "../assets/imgs/gallery/4.jpg";
import img5 from "../assets/imgs/gallery/5.jpg";
import img6 from "../assets/imgs/gallery/6.jpg";
import Carousel from 'carousel-react-rcdev'
import Gallery from "react-photo-gallery";

const photos = [ 
  {
    src: img1,
    width: 7,
    height: 4
  },
  {
    src: img2,
    width: 7,
    height: 4
  },
  {
    src: img3,
    width: 7,
    height: 4
  },
  {
    src: img4,
    width: 7,
    height: 4
  },
  {
    src: img5,
    width: 7,
    height: 4
  },
  {
    src: img6,
    width: 7,
    height: 4
  },

]
const OurGallery = () => {
  return (
    <Style id="gallery">
      <div className="container">
        <h2 data-aos="fade">
          <span>Our</span>Gallery
        </h2>
        <Gallery photos={photos} direction={"column"} />
      </div>
    </Style>
  );
};

export default OurGallery;

const Style = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: ${(props) => props.theme.bg};
  h2 {
    margin-bottom: 72px;
    text-align: center;
    color: #d82525;
    span {
      color: ${(props) => props.theme.text};
    }
  }
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
    h2 {
      margin-bottom: 32px;
    }
  }
`;

const Images = styled.div`
  > :nth-child(odd) {
    > :first-child {
      flex: 2;
      margin-right: clamp(0px, 2vw, 40px);
      @media (max-width: 992px) {
        margin-right: 0;
      }
    }
    > :last-child {
      flex: 1;
      max-height: 400px;
    }
  }
  > :nth-child(even) {
    > :last-child {
      flex: 2;
    }
    > :first-child {
      margin-right: clamp(0px, 2vw, 40px);
      flex: 1;
      max-height: 400px;
      @media (max-width: 992px) {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 992px) {
    img {
      margin-bottom: 20px;
    }
  }
`;

const Row = styled.div`
  margin-bottom: 32px;
  display: flex;
 
  justify-content: space-between;
  img {
    object-fit: cover;
    border-radius: 16px;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    margin: 0 auto 0;
    max-width: 500px;
  }
`;
