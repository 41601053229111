import React from "react";
import styled from "styled-components";

// swiper utils
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

import Card from "./Card";
import Cards from "../cards.js";
import Arrows from "./CarouselArrows";

// Activate Navigation buttons
SwiperCore.use([Navigation, Pagination, A11y]);

const Carousel = () => {
  const params = {
    loop: false,
    navigation: {
      prevEl: ".left",
      nextEl: ".right",
    },
    breakpoints: {
      0: {
        spaceBetween: 10,
        slidesPerView: 1,
      },
      500: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      992: {
        spaceBetween: 40,
        slidesPerView: 3,
      },
    },
  };
  return (
    <Style>
      <Arrows />
      <Swiper {...params}>
        {Cards.map((card, index) => (
          <SwiperSlide key={index}>
            <Card paragraph={card.paragraph} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Style>
  );
};

export default Carousel;

const Style = styled.section``;
