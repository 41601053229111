import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import AOS from "aos";

// Pages
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);
  const darkMode = {
    dark: true,
    bg: "#0F1011",
    text: "#ffffff",
  };
  const lightMode = {
    dark: false,
    bg: "#ffffff",
    text: "#0F1011",
  };
  const currentTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? darkMode : lightMode;
  return (
    <ThemeProvider theme={currentTheme}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;