import React from "react";
import styled from "styled-components";

import Step1 from "./services/Step1";
import Step2 from "./services/Step2";
import Step3 from "./services/Step3";
import Step4 from "./services/Step4";

const OurServices = () => {
  return (
    <Style id="services">
      <h2 data-aos="fade">
        <span>Our</span> Services
      </h2>
      <Step1 />
      <Step2 />
      <Row>
        <div className="container">
          <Step3 className="step3" />
          <Step4 className="step4" />
        </div>
      </Row>
    </Style>
  );
};

export default OurServices;

const Style = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: ${(props) => props.theme.bg};
  h2 {
    margin-bottom: 72px;
    text-align: center;
    color: #d82525;
    span {
      color: ${(props) => props.theme.text};
    }
  }
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
    h2 {
      margin-bottom: 32px;
    }
  }
`;

const Row = styled.div`
  background: #d82525;
  .container {
    display: flex;
    .step3 {
      flex: 2;
      background: #0f1011;
      padding: 86px 65px;
      @media (max-width: 768px) {
        padding: 44px 24px;
      }
    }
    .step4 {
      flex: 3;
      justify-content: center;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;
