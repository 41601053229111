import React from "react";
import styled from "styled-components";

import { ReactComponent as Logo } from "../assets/imgs/logo/logo.svg";
import Map from "../assets/imgs/map.svg";
import Wall from "../assets/imgs/wall.png";
import S1 from "../assets/imgs/footer/s1.png";
import S2 from "../assets/imgs/footer/s2.png";

const Footer = () => {
  return (
    <Style id="contactus">
      <div className="container">
        <div data-aos="fade">
          <Logo />
          <address>
            5111 E 81st Ave
            <br />
            Merrillville, IN 46410
            <br />
            <br />
            kreativekuts2021@gmail.com
            <br />
            219 945-1300
          </address>
          {/* <div>
            <img src={S1} alt="" />
            <img src={S2} alt="" />
          </div> */}
        </div>
        <div className="map" data-aos="fade">
          <img src={Map} alt="map" />
          <img src={Wall} alt="wall" />
        </div>
      </div>
      <p className="rep-footer" data-aos="fade">
        2022 . All right reserved
      </p>
    </Style>
  );
};

export default Footer;

const Style = styled.footer`
  background: #0f1011;
  padding-top: 80px;
  color: #fff;
  .container {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  address {
    font-style: normal;
    margin-bottom: 25px;
    margin-right: 20px;
    z-index: 1;
    position: relative;
  }
  address + div {
    display: flex;
    > *:first-child {
      margin-right: 20px;
    }
  }
  svg {
    margin-bottom: 24px;
    path:last-child {
      fill: #d82525;
    }
  }
  .map {
    position: relative;
    margin-left: auto;
    img:first-child {
      margin-left: auto;
      position: relative;
      z-index: 1;
      border-radius: 16px;
    }
    img:last-child {
      position: absolute;
      bottom: -70px;
      left: -120px;
      z-index: 0;
    }
    @media (max-width: 768px) {
      margin-top: 50px;
      img:last-child {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .rep-footer {
    padding: 100px 15px 37px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
`;
